/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

// import fetchWrapper from "../../../fetchWrapper";

// export const RoleListService = async (pageIndex) => {
//   try {
//     const token = localStorage.getItem("token"); // O desde donde guardes tu token
//     const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/roles?page=${pageIndex}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//         user: user,
//       },
//       body: JSON.stringify(),
//     });
//     const { payload } = await response.json();
//     return { payload };
//   } catch (error) {}
// };

// export const RolePermisionsListService = async () => {
//   try {
//     const token = localStorage.getItem("token"); // O desde donde guardes tu token
//     const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//         user: user,
//       },
//       body: JSON.stringify(),
//     });
//     const { payload } = await response.json();
//     return { payload };
//   } catch (error) {}
// };

// export const RoleByIDService = async (id) => {
//   try {
//     const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/roles/rol/${id}`);
//     const { payload } = await response.json();
//     return { payload };
//   } catch (error) {}
// };
import { fetchWrapper } from "../../../fetchWrapper"; // Importar fetchWrapper

export const RoleCreateService = async (payload) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/roles/roles/create/`;

    // Llamar a fetchWrapper para hacer la solicitud POST
    const result = await fetchWrapper(url, {
      method: "POST",
      body: JSON.stringify(payload), // Pasar el payload en el body
    });

    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
};

export const RoleUpdateService = async (id, payload) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/roles/roles/update/${id}/`;
    const result = await fetchWrapper(url, { method: "PUT", body: JSON.stringify(payload) });
    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export const RoleDeleteService = async (id) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/roles/roles/delete/${id}/`;
    const result = await fetchWrapper(url, { method: "DELETE" });
    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { success: false, message: error.message || message };
  }
};

export async function RoleListService(pageIndex) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/roles/`;
    const result = await fetchWrapper(url, { method: "GET" });
    return result;
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    return { success: false, message: error.message || message };
  }
}

export async function PermissionListService() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/permissions/`;
    const result = await fetchWrapper(url, { method: "GET" });
    return result;
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
}
