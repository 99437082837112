import { useState, useEffect, useRef } from "react";
import {
  ActivitiesCreateService,
  ActivitiesListService,
  CategoriesListService,
} from "../services/ActivitiesServices";
import { ActivitiesCreate } from "../modules/ActivitiesCreate";
import ActivitiesList from "../modules/ActivitiesList";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { bool } from "prop-types";
import FilterController from "components/filter/controller/FilterController";
import data from "layouts/tables/data/authorsTableData";

export default function ActivitiesController() {
  const filterConfig = [
    { visibleName: "Título", dbField: "title", type: "text" },
    { visibleName: "Descripción", dbField: "description", type: "text" },
    { visibleName: "Palabras clave", dbField: "keywords", type: "text" },
    // { visibleName: "Formatos de evidencia", dbField: "evidence_formats", type: "text" },
    { visibleName: "Certificable", dbField: "is_certifiable", type: "boolean" },
    { visibleName: "Documento del participante", dbField: "document", type: "text" },
    // { visibleName: "Categorías", dbField: "categories", type: "text" },
    { visibleName: "Fecha de creación", dbField: "fecha", type: "date" },
  ];

  const dataTable = "activities"; //Tabla indicada
  const filterControllerRef = useRef();
  const [isDisabled, setIsDisabled] = useState(false);
  const [newData, setNewData] = useState({
    title: "",
    fecha: "",
    description: "",
    keywords: "",
    evidence_formats: "",
    is_certifiable: null, // Cambiar a null
    categories: "",
  });
  const [openCreate, setOpenCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [handleActivitiesList, setActivitiesList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = (event) => {
    const { dataset } = event.target;
    const exportData = dataset.export;
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit(exportData);
    }
  };

  const requestActivitiesList = async (pageIndex) => {
    setLoading(true);
    try {
      const result = await ActivitiesListService(pageIndex);
      if (result.success) {
        setActivitiesList(result.payload);
        setTotalPages(result.totalPages || 0);
        setTotalData(result.totalData || 0);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };

  const requestSubCategoriesList = async (pageIndex) => {
    setLoading(true);
    try {
      const result = await CategoriesListService(pageIndex);
      if (result.success) {
        setSubCategoriesList(result.payload);
      } else {
        handleAlert("error", result.message);
      }
    } catch (error) {
      handleAlert("error", "Error al obtener las actividades");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    setPaginaSize(pageSize);
    requestActivitiesList((currentPage - 1) * pageSize + 1);
  };

  const handleActivityCreate = (event) => {
    event.preventDefault();
    setNewData({
      title: "",
      fecha: "",
      description: "",
      keywords: "",
      evidence_formats: "",
      is_certifiable: null, // Cambiar a null
      categories: "",
    });
    setOpenCreate(true);
  };

  const submitActivityCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await ActivitiesCreateService(newData);
      if (result.success) {
        setAlert({ visible: true, message: "Actividad creada con éxito", type: "success" });
        requestActivitiesList();
      } else {
        setAlert({ visible: true, message: result.message, type: "error" });
      }
    } catch (error) {
      setAlert({ visible: true, message: "Error al crear la actividad", type: "error" });
    } finally {
      setLoading(false);
      setOpenCreate(false);
    }
  };

  const closeActivityCreate = () => setOpenCreate(false);

  const handleFieldChange = (name, value) => {
    setNewData((prevData) => ({
      ...prevData,
      [name]: value === "" ? null : value,
    }));
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  useEffect(() => {
    requestActivitiesList();
    requestSubCategoriesList();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {alert.visible && <MDAlert type={alert.type} message={alert.message} />}
      <MDAlert isAlert={isAlert} setIsAlert={setIsAlert} typeAlert={typeAlert} message={message} />
      <FilterController
        filterConfig={filterConfig}
        dataTable={dataTable}
        ref={filterControllerRef}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        setListData={setActivitiesList}
        handleAlert={handleAlert}
      ></FilterController>
      <ActivitiesCreate
        open={openCreate}
        handleClose={closeActivityCreate}
        isDisabled={isDisabled}
        getFieldValue={(field) => (newData[field] !== undefined ? newData[field] : "")}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitActivityCreate}
        listSubCategories={subCategoriesList}
      />
      <ActivitiesList
        handleActivitiesList={handleActivitiesList}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleActivitiesCreate={handleActivityCreate}
        callHandleClickSubmit={callHandleClickSubmit}
      />
    </>
  );
}
