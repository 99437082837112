import {
  Box,
  Divider,
  Icon,
  Modal,
  Typography,
  Card,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export function ParticipantUpdate({ open, handleClose, onSubmit, participant }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const defaultValues = {
    document: "",
    name: "",
    last_name: "",
    state: "",
    gender: "",
    location: "",
    academic_program: "",
    semester: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultValues);
  const [snackbar, setSnackbar] = useState({ type: "", message: "", open: false });

  useEffect(() => {
    if (participant) {
      setFormValues({
        document: participant.document || "",
        name: participant.name || "",
        last_name: participant.last_name || "",
        state: participant.state || "",
        gender: participant.gender || "",
        location: participant.location || "",
        academic_program: participant.academic_program || "",
        semester: participant.semester || "",
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [participant, open]);

  const handleChange = (event, type) => {
    const { name, value } = event.target;
    validationFields(name, value);
    setFormValues({
      ...formValues,
      [name]: type !== "select" ? capitalizeFirstLetter(value) : value,
    });
  };

  const capitalizeFirstLetter = (input) => {
    let palabras = input.split(" ");
    for (let a = 0; a < palabras.length; a++) {
      let letra = palabras[a].charAt(0).toUpperCase();
      palabras[a] = letra + palabras[a].substring(1).toLowerCase();
    }
    return palabras.join(" ");
  };

  const validationFields = (fieldName, value) => {
    let isValidField = true;
    let newErrors = { ...errors };

    if (value !== undefined && value !== null && value !== "") {
      if (fieldName === "document") {
        if (!/^\d+$/.test(value)) {
          isValidField = false;
          newErrors[fieldName] = {
            message: "El campo debe ser numérico",
            error: true,
          };
        }
      }
      if (isValidField) {
        newErrors[fieldName] = { message: "", error: false };
      }
    } else {
      isValidField = false;
      newErrors[fieldName] = {
        message: "El campo es requerido",
        error: true,
      };
    }

    setErrors(newErrors);
    return isValidField;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let isValidForm = true;
    let newErrors = { ...errors };

    for (let formValue in formValues) {
      const isValidField = validationFields(formValue, formValues[formValue]);
      if (!isValidField) {
        isValidForm = false;
      }
    }

    if (isValidForm && !Object.values(newErrors).some((error) => error.error)) {
      try {
        await onSubmit({ ...formValues, id: participant.id });
        setSnackbar({ type: "success", message: "Participante actualizado con éxito", open: true });
        handleClose();
      } catch (error) {
        setSnackbar({ type: "error", message: "Error al actualizar el participante", open: true });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography id="modal-modal-title" component="h2">
              {participant ? "Editar Participante" : "Crear Participante"}
            </Typography>
            <Icon
              sx={{
                fontSize: "2em",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </MDBox>
          <Divider variant="middle" />
          <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Cédula o Identificación"
              variant="outlined"
              name="document"
              value={formValues.document}
              onChange={handleChange}
              error={errors && errors.document?.error}
              helperText={errors && errors.document?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Nombre"
              variant="outlined"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              error={errors && errors.name?.error}
              helperText={errors && errors.name?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Apellido"
              variant="outlined"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              error={errors && errors.last_name?.error}
              helperText={errors && errors.last_name?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Estado"
              variant="outlined"
              name="state"
              value={formValues.state}
              onChange={handleChange}
              error={errors && errors.state?.error}
              helperText={errors && errors.state?.message}
              sx={{ mb: 2 }}
            />
            {/* <FormControl sx={{ mb: 2 }} fullWidth error={errors && errors.state?.error}>
              <InputLabel id="select-state-label">Estado</InputLabel>
              <Select
                labelId="select-state-label"
                id="select-state"
                value={formValues.state}
                label="Estado"
                name="state"
                onChange={(event) => handleChange(event, "select")}
                sx={{ minHeight: 44 }}
                displayEmpty
              >
                <MenuItem value="" defaultChecked>
                  Seleccione...
                </MenuItem>
                <MenuItem value="Académico">Académico</MenuItem>
                <MenuItem value="Contratista">Contratista</MenuItem>
                <MenuItem value="Invitado">Invitado</MenuItem>
              </Select>
              <FormHelperText>{errors && errors.state?.message}</FormHelperText>
            </FormControl> */}
            <FormControl sx={{ mb: 2 }} fullWidth error={errors && errors.gender?.error}>
              <InputLabel id="select-gender-label">Género</InputLabel>
              <Select
                labelId="select-gender-label"
                id="select-gender"
                value={formValues.gender}
                label="Género"
                name="gender"
                onChange={(event) => handleChange(event, "select")}
                sx={{ minHeight: 44 }}
                displayEmpty
              >
                <MenuItem value="">Seleccione...</MenuItem>
                <MenuItem value="Femenino">Femenino</MenuItem>
                <MenuItem value="Masculino">Masculino</MenuItem>
                <MenuItem value="Otro">Otro</MenuItem>
              </Select>
              <FormHelperText>{errors && errors.gender?.message}</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              label="Ubicación Geográfica"
              variant="outlined"
              name="location"
              value={formValues.location}
              onChange={handleChange}
              error={errors && errors.location?.error}
              helperText={errors && errors.location?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Programa Académico"
              variant="outlined"
              name="academic_program"
              value={formValues.academic_program}
              onChange={handleChange}
              error={errors && errors.academic_program?.error}
              helperText={errors && errors.academic_program?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Semestre"
              variant="outlined"
              name="semester"
              value={formValues.semester}
              onChange={handleChange}
              error={errors && errors.semester?.error}
              helperText={errors && errors.semester?.message}
              sx={{ mb: 2 }}
            />
            <MDBox mt={2} display="flex" justifyContent="space-between">
              <MDButton type="submit" variant="gradient" color="info">
                {participant ? "Actualizar" : "Crear"}
              </MDButton>
              <MDButton variant="outlined" color="info" onClick={handleClose}>
                Cancelar
              </MDButton>
            </MDBox>
          </Box>
        </Card>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000} // Duración en ms
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Ubicación
        key={snackbar.type} // Asegúrate de usar una clave única
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type} sx={{ width: "100%" }}>
          <AlertTitle>
            {snackbar.type === "success" ? "La solicitud se hizo correctamente " : "Error"}
          </AlertTitle>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
