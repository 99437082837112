import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import ParticipantList from "../modules/ParticipantList";
import { ParticipantCreate } from "../modules/ParticipantCreate";
import {
  ParticipantCreateService,
  ParticipantListService,
  participantUploadService,
} from "../services/ParticipantService";
import { ParticipantUpload } from "../modules/ParticipantUpload";
import FilterController from "components/filter/controller/FilterController";

export default function ParticipantsController() {
  const filterConfig = [
    { visibleName: "Documento", dbField: "document", type: "text" },
    { visibleName: "Nombre", dbField: "name", type: "text" },
    { visibleName: "Apellido", dbField: "last_name", type: "text" },
    // { visibleName: "Estado", dbField: "state", type: "text" },
    // { visibleName: "Género", dbField: "gender", type: "text" },
    { visibleName: "Ubicación Geográfica", dbField: "location", type: "text" },
    { visibleName: "Programa Académico", dbField: "academic_program", type: "text" },
    { visibleName: "Semestre", dbField: "semester", type: "text" },
    { visibleName: "Fecha de creación", dbField: "created_at", type: "date" },
    { visibleName: "Fecha de actualización", dbField: "updated_at", type: "date" },
  ];

  const dataTable = "participants"; //Tabla indicada

  const { id } = useParams(); // Obtener el ID del rol desde la URL
  // const navigate = useNavigate(); // Para redirigir después de guardar
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [handleParticipantList, setParticipantList] = useState();

  const [openCreate, setOpenCreate] = useState(false);
  const closeCreate = () => setOpenCreate(false);
  const handleParticipantCreate = (event) => {
    event.preventDefault();
    setOpenCreate(true);
  };

  const requestParticipantList = async (pageIndex) => {
    const result = await ParticipantListService(id, pageIndex);
    // console.log(result);
    if (result.success) {
      await setParticipantList(result.payload);
    } else {
      handleAlert("fail", result.message);
    }
  };

  const handleParticipantSubmit = async (formValues) => {
    setLoading(true);
    // Agregar el activity ID al objeto formValues
    const updatedFormValues = {
      ...formValues,
      activity_id: id,
    };
    // const updatedFormValues = {
    //   document: formValues.document,
    //   name: formValues.name,
    //   last_name: formValues.last_name,
    //   state: formValues.state,
    //   gender: formValues.gender,
    //   location: formValues.location,
    //   academic_program: formValues.academic_program,
    //   semester: formValues.semester,
    //   activity_id: id,
    // };

    const result = await ParticipantCreateService(updatedFormValues);

    if (result.success) {
      await requestParticipantList(1);
      //Mandar mensaje de exito para renderizar en la alerta
      handleAlert("success", "Evidencia creada con éxito");
    } else {
      //Mandar mensaje de error para renderizar en la alerta
      handleAlert("fail", result.message);
    }
    closeCreate();
    setLoading(false);
  };

  // Handle alerts
  const handleAlert = (typeAlert, message) => {
    setMessage({ type: typeAlert, text: message });
    setIsAlert(true);
  };

  useEffect(() => {
    requestParticipantList();
  }, []);

  //--------------------Carga masiva--------------------

  const [openNewUpload, setopenNewUpload] = useState(false);
  const [newDataUpload, setNewDataUpload] = useState({});
  const [isFormModifiedUpload, setIsFormModifiedUpload] = useState(false);
  const [isDisabledUpload, setIsDisabledUpload] = useState(false);
  const handleCloseNewUpload = () => setopenNewUpload(false);
  //Abrir modal para hacer carga masiva por fuente de datos
  const handleopenNewUpload = async (event) => {
    event.preventDefault();
    setLoading(true);
    resetFieldValidUpload();

    //const { dataset } = event.currentTarget;
    const data = {
      name: "EXCEL",
      source: "",
    };
    setNewDataUpload(data);
    setopenNewUpload(true);
    setLoading(false);
  };

  // Maneja el envío de la fuente de datos para ser consumida segun su formato
  const handleSubmitNew = async (e) => {
    e.preventDefault();
    setIsAlert(false);
    setLoading(true);
    if (newDataUpload.name === "EXCEL") {
      const formData = new FormData();
      formData.append("name", newDataUpload.name);
      formData.append("source", newDataUpload.source);
      // Quitar los guiones del id
      const idWithoutHyphens = id.replace(/-/g, "");
      console.log(idWithoutHyphens);
      formData.append("activity_id", idWithoutHyphens);
      const file = getFieldValueNewUpload("source").type;

      if (
        file === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file === "application/vnd.ms-excel"
      ) {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        await requestSourceNew(formData);

        setLoading(false);
        handleCloseNewUpload();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo EXCEL");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    }

    //const convertedObject = convertValuesToString(newData);
    // Realiza la acción de enviar el formulario aquí
    //requestSourceNew(newData);
    setLoading(false);
  };
  const getFieldValueNewUpload = (fieldName) => {
    return newDataUpload[fieldName];
  };
  const handleFieldChangeNewUpload = (fieldName, value) => {
    setNewDataUpload({
      ...newDataUpload,
      [fieldName]: value,
    });
    handleChangeValidUpload(fieldName, value);
  };

  // Esperar respuesta de la función createSource y validar respuesta para conocer si se inserto correctamente la fuente de datos o mostrar error
  const requestSourceNew = async (data) => {
    try {
      const result = await participantUploadService(data);
      if (result.success) {
        const totalErrors = result.payload.error_messages.length;
        const totalSuccess = result.payload.info_line.successLine;
        //Mandar mensaje de exito para renderizar en la alerta
        handleAlert(
          "success",
          `Se ha creado la fuente de datos\n
          Total de registros insertados: ${totalSuccess}\n
          errores en la fuente: ${totalErrors}`
        );
      } else {
        //Mandar mensaje de error para renderizar en la alerta
        handleAlert("fail", result.message);
      }
      // console.log(result);
    } catch (error) {
      handleAlert("fail", "Fallo al procesar el archivo");
    }
  };

  // Estado de las validaciones
  const [isFieldValidUpload, setIsFieldValidUpload] = useState({
    name: true,
    EXCEL: true,
  });

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValidUpload = (fieldName, value) => {
    if (fieldName === "source") {
      fieldName = newDataUpload.name; // se reemplaza "source" por el tipo de archivo actual "CSV" || "EXCEL" || "JSON"
      // Verifica si se ha seleccionado algún archivo
      if (!value) {
        setIsFieldValidUpload((prevValidity) => ({
          ...prevValidity,
          [fieldName]: true,
        }));
        return;
      }
      const isFieldValid = validateFieldUpload(value.name, validationPatternsUpload[fieldName]);
      console.log(isFieldValid);
      setIsFieldValidUpload((prevValidity) => ({
        ...prevValidity,
        [fieldName]: isFieldValid,
      }));
      return;
    }

    // Para otros campos, valida el valor ingresado (o seleccionado)
    const isFieldValid = validateFieldUpload(value.trim(), validationPatternsUpload[fieldName]);

    setIsFieldValidUpload((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Compara el valor del input con la expresion regular requerida
  const validateFieldUpload = (value, pattern) => {
    return pattern.test(value);
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatternsUpload = {
    name: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    EXCEL: /\.(xlsx|xls)$/, // Archivos con extension .xlsx y xls
  };

  // Setea los valores de las validaciones
  const resetFieldValidUpload = () => {
    setIsFieldValidUpload({
      name: true,
      EXCEL: true,
    });
  };

  useEffect(() => {
    // Comprueba si algún campo ha cambiado desde los valores iniciales
    //const hasFormChanged = Object.keys(newData).some((fieldName) => newData[fieldName] !== "");
    //const requiredFields = ["name", "source", "perfil", "created"];
    const requiredFields = Object.keys(newDataUpload).filter(
      (clave) => clave !== "db_password" && clave !== "created" && clave !== "expires_in"
    );
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newDataUpload[fieldName]);
    setIsFormModifiedUpload(someFieldIsEmpty);
  }, [newDataUpload]);

  //--------------------FIN Carga masiva--------------------

  //Cambia el valor de currentPage a la página elegida
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  //Editar particpantes

  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={message.type}
        message={message.text}
      />
      <FilterController
        filterConfig={filterConfig}
        dataTable={dataTable}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        setListData={setParticipantList}
        activity_id={id}
        handleAlert={handleAlert}
      ></FilterController>

      <ParticipantCreate
        open={openCreate}
        handleClose={closeCreate}
        onSubmit={handleParticipantSubmit}
      />

      <ParticipantUpload
        open={openNewUpload}
        handleClose={handleCloseNewUpload}
        getFieldValue={getFieldValueNewUpload}
        handleFieldChange={handleFieldChangeNewUpload}
        handleSubmit={handleSubmitNew}
        isFieldValid={isFieldValidUpload}
        isFormModified={isFormModifiedUpload}
        isDisabled={isDisabledUpload}
      />

      <ParticipantList
        handleParticipantList={handleParticipantList}
        handleParticipantCreate={handleParticipantCreate}
        handleopenNewUpload={handleopenNewUpload}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </>
  );
}
