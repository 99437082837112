import MDInput from "components/MDInput";
import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Alert, FormHelperText } from "@mui/material";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import BackspaceIcon from "@mui/icons-material/Backspace";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Filter({
  dataFilter,
  filter,
  handleFilter,
  isVisible,
  handleChange,
  search,
  filterType,
  handleFilterType,
  isVisibleSelectDateInput,
  isVisibleSelectDate,
  isVisibleType,
  dateFrom,
  dateUntil,
  handleChangeDateFrom,
  handleChangeDateUntil,
  handleClickSubmit,
  dataTypeCard,
  dataStatus,
  dataSourceTypes,
  handleAddFilter,
  filters,
  showErrorAlert,
  handleDeleteFilter,
  isFieldValid,
  filterConfig,
}) {
  return (
    <>
      {/* {showErrorAlert && <Alert severity="error">El filtro ya está en la lista o es vacío</Alert>} */}
      {filters.length !== 0 && (
        <div>
          {filters.map((item) => (
            <MDButton
              endIcon={<BackspaceIcon />}
              variant="contained"
              color="error"
              size="medium"
              style={{ marginTop: "25px" }}
              key={item.id}
              sx={{ m: 1 }} // Establecer el color del texto a blanco
              onClick={() => handleDeleteFilter(item.id)}
            >
              {item.label}
            </MDButton>
          ))}
        </div>
      )}
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <FormControl style={{ left: "20px" }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Filtrado por...</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filtrado por..."
            onChange={handleFilter}
          >
            <MenuItem value="">NINGUNO</MenuItem>
            {filterConfig.map((item, index) => (
              <MenuItem key={index} value={item.dbField}>
                {item.visibleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isVisible && (
          <>
            <MDInput
              error={!isFieldValid.search}
              helperText={!isFieldValid.search ? "Ingrese un valor." : ""}
              style={{
                left: "20px",
                marginRight: "5px",
              }}
              label="Buscar"
              onChange={handleChange}
              value={search}
            />
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
        <>
          {isVisibleSelectDate && (
            <>
              <MDInput
                type="date"
                label="Desde"
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                value={dateFrom}
                onChange={handleChangeDateFrom}
              />
              <MDInput
                type="date"
                label="Hasta"
                value={dateUntil}
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                onChange={handleChangeDateUntil}
              />
              <MDButton
                style={{ left: "20px" }}
                variant="gradient"
                color="info"
                size="small"
                onClick={handleAddFilter}
              >
                Agregar Filtro
              </MDButton>
            </>
          )}
        </>
        {isVisibleType && filterSourceData && (
          <>
            <FormControl>
              <InputLabel>Selecciona...</InputLabel>
              <Select
                value={search}
                style={{
                  left: "20px",
                  marginRight: "5px",
                }}
                onChange={handleFilterType}
              >
                <MenuItem value="">Ninguno</MenuItem>
                {filterSourceData[selectedFilter.source].map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
      </div>
      <MDButton
        style={{ left: "20px", marginTop: "10px" }}
        variant="gradient"
        color="info"
        onClick={handleClickSubmit}
      >
        Buscar
      </MDButton>
    </>
  );
}
