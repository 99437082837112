// fetchWrapper.js
export async function fetchWrapper(url, options = {}) {
  // Obtener el token del localStorage
  const token = localStorage.getItem("token");

  // Asegurar que las cabeceras existen
  options.headers = options.headers || {};

  // Añadir el token al encabezado si existe
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  // Asegurar que el Content-Type sea JSON si no está definido
  if (!options.headers["Content-Type"]) {
    options.headers["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Error del servidor al realizar la solicitud");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
}
