import React, { useEffect, useState } from "react";
import { Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PropTypes from "prop-types";
import usePermission from "hooks/usePermission";

export default function EvidenceList({
  handleEvidenceCreate,
  handleEvidenceList,
  handleRoleDelete,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
}) {
  // Definir las columnas para la tabla
  const columns = [
    { Header: "Descripción", accessor: "description", align: "left" },
    { Header: "Archivo", accessor: "file", align: "left" },
    { Header: "Fecha Creación", accessor: "created", align: "left" },
    { Header: "Google Drive", accessor: "drive_file_link", align: "left" },
    { Header: "Acciones", accessor: "actions", align: "center" },
  ];

  // Construir las filas basadas en la lista de evidencias
  const rows = handleEvidenceList
    ? handleEvidenceList.map((evidence) => ({
        description: <MDTypography variant="caption">{evidence.description}</MDTypography>,
        file: <MDTypography variant="caption">{decodeURIComponent(evidence.file)}</MDTypography>, // Nombre del archivo decodificado
        created: (
          <MDTypography variant="caption">
            {new Date(evidence.created).toLocaleString()}
          </MDTypography>
        ),
        drive_file_link: (
          <a href={evidence.drive_file_link} target="_blank" rel="noopener noreferrer">
            Ver archivo
          </a>
        ), // Enlace de Google Drive
        actions: (
          <MDBox>
            {/*  <MDButton variant="text" color="info">
              <EditOutlinedIcon />
            </MDButton> */}
            <MDButton variant="text" color="error">
              <a
                data-id={evidence.id}
                data-description={evidence.description}
                onClick={handleRoleDelete}
                style={{ marginLeft: "8px", cursor: "pointer" }}
              >
                <DeleteForeverOutlinedIcon
                  titleAccess="Eliminar"
                  style={{
                    fontSize: "200px",
                    width: "24px",
                    height: "24px",
                    backgroundImage: "#000000",
                  }}
                />
              </a>
            </MDButton>
          </MDBox>
        ),
      }))
    : [];

  return (
    <>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2vw",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <MDButton onClick={handleEvidenceCreate} variant="gradient" color="info">
          Crear
        </MDButton>
      </MDBox>

      {/* Mostrar la tabla con DataTable */}
      <DataTable
        table={{ columns, rows }}
        isSorted={true} // Habilitar ordenamiento
        entriesPerPage={false} // Mostrar control de entradas por página
        showTotalEntries={false} // Mostrar el total de entradas
      />
      <>
        <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
          <MDTypography
            style={{ left: "20px" }}
            variant="button"
            color="secondary"
            fontWeight="regular"
          >
            Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
          </MDTypography>
        </MDBox>
        <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          ></Pagination>
        </Stack>{" "}
      </>
    </>
  );
}
