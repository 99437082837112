import { useState, useEffect } from "react";

const usePermission = (requiredPermission, requiredModule) => {
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    // Leer los permisos directamente desde el localStorage
    const storedPermissions = JSON.parse(localStorage.getItem("permissions")) || [];
    // Leer el rol del usuario desde el localStorage
    const userRole = JSON.parse(localStorage.getItem("role")); // Asegúrate de almacenar el rol en localStorage

    // Si el rol es "superadmin", le damos acceso a todo
    if (userRole.name === "Superadmin") {
      setHasPermission(true);
    } else {
      // Verificar si el usuario tiene el permiso requerido
      setHasPermission(
        storedPermissions.some(
          (permission) =>
            permission.name === requiredPermission && permission.module === requiredModule
        )
      );
    }
  }, [requiredPermission, requiredModule]);

  return hasPermission;
};

export default usePermission;
